/* global document */
import { h } from './element';
import { unbindClickoutside } from './event';
import { cssPrefix } from '../config';
import { tf } from '../locale/locale';

const menuItems = [
  { key: 'copy', title: tf('contextmenu.copy'), label: 'Ctrl+C' },
  { key: 'cut', title: tf('contextmenu.cut'), label: 'Ctrl+X' },
  { key: 'paste', title: tf('contextmenu.paste'), label: 'Ctrl+V' },
  // { key: 'paste-value', title: tf('contextmenu.pasteValue'), label: 'Ctrl+Shift+V' },
  // { key: 'paste-format', title: tf('contextmenu.pasteFormat'), label: 'Ctrl+Alt+V' },
  { key: 'divider' },
  { key: 'insert-row', title: tf('contextmenu.insertRow') },
  { key: 'insert-column', title: tf('contextmenu.insertColumn') },
  { key: 'divider' },
  { key: 'delete-row', title: tf('contextmenu.deleteRow') },
  { key: 'delete-column', title: tf('contextmenu.deleteColumn') },
  { key: 'delete-cell-text', title: tf('contextmenu.deleteCellText') },
  { key: 'hide', title: tf('contextmenu.hide') },
  // { key: 'divider' },
  // { key: 'validation', title: tf('contextmenu.validation') },
  // { key: 'divider' },
  // { key: 'cell-printable', title: tf('contextmenu.cellprintable') },
  // { key: 'cell-non-printable', title: tf('contextmenu.cellnonprintable') },
  // { key: 'divider' },
  // { key: 'cell-editable', title: tf('contextmenu.celleditable') },
  // { key: 'cell-non-editable', title: tf('contextmenu.cellnoneditable') },
];

function buildMenuItem(item) {
  if (item.key === 'divider') {
    return h('div', `${cssPrefix}-item divider`).attr('role', 'separator').attr('aria-disabled', true);
  }
  return h('div', `${cssPrefix}-item`)
    .attr('role', 'menuitem')
    .on('click', () => {
      this.itemClick(item.key);
      this.hide();
    })
    .children(
      item.title(),
      h('div', 'label').child(item.label || ''),
    );
}

function buildMenu() {
  return menuItems.map(it => buildMenuItem.call(this, it));
}

export default class ContextMenu {
  constructor(viewFn, isHide = false) {
    this.menuItems = buildMenu.call(this);
    this.el = h('div', `${cssPrefix}-contextmenu`)
      .attr('role', 'menu')
      .attr('aria-haspopup', true)
      .children(...this.menuItems)
      .hide();
    this.viewFn = viewFn;
    this.itemClick = () => {};
    this.isHide = isHide;
    this.setMode('range');
  }

  // row: the whole rows
  // col: the whole cols
  // range: select range
  setMode(mode) {
    const hideEl = this.menuItems[10];
    const deleteRowEl = this.menuItems[7];
    const deleteColEl = this.menuItems[8];
    if (mode === 'row') {
      hideEl.show();
      deleteRowEl.show();
      deleteColEl.hide();
    } else if (mode === 'col') {
      hideEl.show();
      deleteRowEl.hide();
      deleteColEl.show();
    } else {
      hideEl.hide();
      deleteRowEl.show();
      deleteColEl.show();
    }
  }

  hide() {
    const { el } = this;
    el.hide();
    unbindClickoutside(el);
  }

  setPosition(x, y, evt) {
    if (this.isHide) return;
    const { el } = this;
    const { width } = el.show().offset();
    const view = this.viewFn();
    // eslint-disable-next-line no-undef
    const vhf = window.innerHeight / 2;
    let left = x;
    if (view.width - x <= width) {
      left -= width;
    }
    el.css('position', 'absolute');
    el.css('left', `${left}px`);

    const { left: cLeft } = el.el.getBoundingClientRect();
    el.css('position', 'fixed');
    el.css('left', `${cLeft}px`);
    el.css('zIndex', '99999');
    if (evt.clientY > vhf) {
      // eslint-disable-next-line no-undef
      el.css('bottom', `${window.innerHeight - evt.clientY}px`)
        .css('max-height', `${evt.clientY - 80}px`)
        .css('top', 'auto');
    } else {
      el.css('top', `${evt.clientY}px`)
        // eslint-disable-next-line no-undef
        .css('max-height', `${window.innerHeight - 50 - y}px`)
        .css('bottom', 'auto');
    }
    setTimeout(() => {
      document.body.classList.add('zond-never-scroll');
    });
    // eslint-disable-next-line no-use-before-define
    bodyClickHandler(el);
  }
}

function unbindBodyClickHandler(el) {
  if (el.bodyClickHandler) {
    // eslint-disable-next-line no-undef
    window.document.body.removeEventListener('click', el.bodyClickHandler);
    delete el.xclickoutside;
  }
}


function bodyClickHandler(el) {
  el.bodyClickHandler = () => {
    el.hide();
    unbindBodyClickHandler(el);
    // eslint-disable-next-line no-undef
    document.body.classList.remove('zond-never-scroll');
  };
  // eslint-disable-next-line no-undef
  document.body.addEventListener('click', el.bodyClickHandler);
}
