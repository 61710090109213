/* global document */
import { Element, h } from './element';
import { bindClickoutside, unbindClickoutside } from './event';
import { cssPrefix } from '../config';

export default class Dropdown extends Element {
  constructor(title, width, showArrow, placement, ...children) {
    super('div', `${cssPrefix}-dropdown ${placement}`);
    this.useFixed = true;
    this.title = title;
    this.change = () => {};
    this.headerClick = () => {};
    if (typeof title === 'string') {
      this.title = h('div', `${cssPrefix}-dropdown-title`).child(title);
    } else if (showArrow) {
      this.title.addClass('arrow-left');
    }
    this.contentEl = h('div', `${cssPrefix}-dropdown-content`)
      .css('width', width)
      .attr('role', 'listbox')
      .hide();

    this.setContentChildren(...children);

    this.headerEl = h('div', `${cssPrefix}-dropdown-header`);
    this.headerEl.on('click', () => {
      if (this.contentEl.css('display') !== 'block') {
        this.show();
      } else {
        this.hide();
      }
    }).children(
      this.title,
      showArrow ? h('div', `${cssPrefix}-icon arrow-right`).child(
        h('div', `${cssPrefix}-icon-img arrow-down`),
      ) : '',
    );
    this.children(this.headerEl, this.contentEl);
  }

  setContentChildren(...children) {
    this.contentEl.html('');
    if (children.length > 0) {
      this.contentEl.children(...children);
    }
  }

  setTitle(title) {
    this.title.html(title);
    this.hide();
  }

  show() {
    const { contentEl } = this;
    contentEl.show();
    if (this.useFixed) {
      const { left: cLeft, top: cTop, height } = contentEl.el.getBoundingClientRect();
      // eslint-disable-next-line no-undef
      contentEl.css('height', `${Math.min(window.innerHeight - 80 - cTop, height)}px`);
      contentEl.css('overflow', 'auto');
      contentEl.css('position', 'fixed');
      contentEl.css('top', `${cTop}px`);
      contentEl.css('left', `${cLeft}px`);
      // eslint-disable-next-line no-undef
    }
    setTimeout(() => {
      document.body.classList.add('zond-never-scroll');
    });
    this.parent().active().attr('aria-expanded', true);
    bindClickoutside(this.parent(), () => {
      this.hide();
    });
  }

  hide() {
    this.parent().active(false).attr('aria-expanded', false);
    this.contentEl.hide();
    if (this.useFixed) {
      this.contentEl.css('position', 'absolute');
      this.contentEl.css('top', 'calc(100% + 5px)');
      this.contentEl.css('left', '0');
      this.contentEl.css('height', 'auto');
    }
    this.contentEl.css('overflow', 'auto');
    unbindClickoutside(this.parent());
    document.body.classList.remove('zond-never-scroll');
  }
}
