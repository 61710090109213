import { LIST_TYPE_ENUM } from '../../constant';
import { getLineListType, getTextAreaSelectLineRange } from '../../core/bullet_numeric';

function emptyStrCell() {
  return { text: '' };
}

function bulletNumericListResolver({ oldIsNumericFn, oldIsBulletFn, oldIsEmptyFn }) {
  const { data, editor } = this;
  const { ri, ci } = data.selector;
  let cell = this.data.getCell(ri, ci);
  if (cell === null) {
    cell = emptyStrCell();
  }
  let selectedOrFullTextLinesList;
  if (editor.isEditing) {
    const textAreaEl = editor.textEl.el;
    const { startLineNo, endLineNo } = getTextAreaSelectLineRange(textAreaEl);
    selectedOrFullTextLinesList = textAreaEl.value.split('\n').slice(startLineNo, endLineNo + 1);
  } else {
    selectedOrFullTextLinesList = cell.text.split('\n');
  }
  const switcher = selectedOrFullTextLinesList.reduce((prev, line, index) => {
    if (index === 0) {
      return getLineListType(line);
    }
    if (getLineListType(line) === prev) {
      return prev;
    }
    return null;
  }, null);
  const params = {
    data,
    cell,
    ri,
    ci,
    editor,
    isEditing: editor.isEditing,
  };
  switch (switcher) {
    case LIST_TYPE_ENUM.numeric:
      oldIsNumericFn(params);
      break;
    case LIST_TYPE_ENUM.bullet:
      oldIsBulletFn(params);
      break;
    default:
      oldIsEmptyFn(params);
      break;
  }
  this.table.render();
}

export {
  emptyStrCell,
  bulletNumericListResolver,
};
