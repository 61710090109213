import { getFontSizePxByPt } from './font';

function fr(text, style) {
  let res;
  if (typeof text === 'string' && style && Array.isArray(style.fullStyle)) {
    res = text.split('').map((i, index) => {
      const s = style.fullStyle.reduce((prev, fs) => {
        if (index >= fs.s && index < (fs.e !== null ? fs.e : Infinity)) {
          return { ...prev, ...fs };
        }
        return prev;
      }, {});
      delete s.s;
      delete s.e;
      return Object.entries(s).reduce((prev, [k, v]) => {
        if (k === 'bgColor') {
          // eslint-disable-next-line no-undef
          let a = document.createElement('div');
          a.innerHTML = prev;
          a.querySelector('span').style.backgroundColor = v;
          const result = a.innerHTML;
          a = null;
          return result;
        }
        if (k === 'strike' && v) {
          return `<strike>${prev}</strike>`;
        }
        if (k === 'underline' && v) {
          return `<u>${prev}</u>`;
        }
        if (k === 'color') {
          return `<span style="color: ${v}">${prev}</span>`;
        }
        if (k === 'bgcolor') {
          return `<span style="background-color: ${v}">${prev}</span>`;
        }
        if (k === 'bold' && v) {
          return `<b>${prev}</b>`;
        }
        if (k === 'italic' && v) {
          return `<i>${prev}</i>`;
        }
        if (k === 'name') {
          return `<font face="${v}">${prev}</font>`;
        }
        if (k === 'size') {
          return `<span style="font-size: ${getFontSizePxByPt(v || 10)}px">${prev}</span>`;
        }
        return prev;
      }, `<span data-selectindex="${index}">${i}</span>`);
    }).join('');
  } else if (typeof text === 'string') {
    res = text
      .split('')
      .map((i, index) => `<span data-selectindex="${index}">${i}</span>`)
      .join('');
  }
  return res;
}

function instantRender(el, text, style) {
  el.html(fr(text, style));
}

function findFullStyleByLetterIndex(fullStyle, index) {
  const style = (fullStyle || []).reduce((prev, fs) => {
    if (index >= fs.s && index < (fs.e !== null ? fs.e : Infinity)) {
      return { ...prev, ...fs };
    }
    return prev;
  }, {});
  delete style.s;
  delete style.e;
  return style;
}

export {
  // eslint-disable-next-line import/prefer-default-export
  instantRender,
  fr,
  findFullStyleByLetterIndex,
};
