import IconItem from './icon_item';
import { bulletNumericListResolver } from './toolbar_utils';
import {
  esAddNumericLabel,
  esRemoveBulletAddNumeric,
  esRemoveNumericLabel,
  rsAddNumericLabel,
  rsRemoveBulletAddNumeric,
  rsRemoveNumericLabel,
} from '../../core/bullet_numeric';

export default class NumericList extends IconItem {
  constructor() {
    super('numeric_list');
  }
}

export function numericListHandler() {
  bulletNumericListResolver.call(this, {
    oldIsNumericFn({ cell, isEditing, editor }) {
      if (!isEditing) {
        rsRemoveNumericLabel(cell);
      } else {
        esRemoveNumericLabel(cell, editor);
      }
    },
    oldIsBulletFn({
      cell, data, isEditing, editor, ri, ci,
    }) {
      if (!isEditing) {
        rsRemoveBulletAddNumeric(cell, data, ri, ci);
      } else {
        esRemoveBulletAddNumeric(cell, editor, data);
      }
    },
    oldIsEmptyFn({
      cell, data, ri, ci, isEditing, editor,
    }) {
      if (!isEditing) {
        rsAddNumericLabel(cell, data, ri, ci);
      } else {
        esAddNumericLabel(cell, editor, data);
      }
    },
  });
}
