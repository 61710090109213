export const BULLET_CHAR = '• ';
export const LIST_TYPE = 'listType';
export const LIST_TYPE_ENUM = {
  bullet: 'bullet-list',
  numeric: 'numeric-list',
};
export const NUMERIC_REG = /^\d*\. /;

export const TEXT_WRAPPING = 'textWrapping';
export const TEXT_CLIP = 'text_clip';
export const TEXT_OVERFLOW = 'text_overflow';
export const TEXT_WRAP = 'text_wrap';
