//* global window */
import { h } from './element';
import Suggest from './suggest';
import Datepicker from './datepicker';
import { cssPrefix } from '../config';
import { setCaretToPos } from '../core/set_textarea_cursor';
import {
  BULLET_CHAR, LIST_TYPE, LIST_TYPE_ENUM, NUMERIC_REG,
} from '../constant';
import { emptyStrCell } from './toolbar/toolbar_utils';
import { instantRender } from '../core/instant_style_render';
// import { mouseMoveUp } from '../event';

const tickMs = 30;

function removeAllCursor(fakerEl) {
  fakerEl.el.querySelectorAll('[data-selectindex]').forEach((i) => {
    i.classList.remove('cursor');
    i.style.border = 'none';
  });
}

function addCursorToIndex(fakerEl, inputIndex, position = 'right') {
  const realIndex = inputIndex < 0 ? 0 : inputIndex;
  setTimeout(() => {
    // remove all border
    removeAllCursor(fakerEl);
    const biu = fakerEl.el.querySelector(`[data-selectindex="${+realIndex}"]`);
    if (!biu) {
      return;
    }
    if (biu.textContent === '\n') {
      // go to next line start
      addCursorToIndex(fakerEl, inputIndex + 1, 'left');
      return;
    }
    biu.classList.add('cursor');
    if (inputIndex < 0 || position === 'left') {
      biu.style.borderLeft = '1px solid';
    } else {
      biu.style.borderRight = '1px solid';
    }
  }, tickMs);
}

function resetTextareaSize() {
  const { inputText } = this;
  const {
    textlineEl, textEl, areaOffset, fakerEl,
  } = this;
  if (!/^\s*$/.test(inputText)) {
    const txts = inputText.split('\n');
    const maxTxtSize = Math.max(...txts.map(it => it.length));
    const tlOffset = textlineEl.offset();
    const fontWidth = tlOffset.width / inputText.length;
    const tlineWidth = (maxTxtSize + 1) * fontWidth + 5;
    const maxWidth = this.viewFn().width - areaOffset.left - fontWidth;
    let h1 = txts.length;
    if (tlineWidth > areaOffset.width) {
      let twidth = tlineWidth;
      if (tlineWidth > maxWidth) {
        twidth = maxWidth;
        h1 += parseInt(tlineWidth / maxWidth, 10);
        h1 += (tlineWidth % maxWidth) > 0 ? 1 : 0;
      }
      textEl.css('width', `${twidth}px`);
    }
    // h1 *= this.rowHeight;
    // if (h1 > areaOffset.height) {
    //   textEl.css('height', `${h1}px`);
    // }
    const { height, width } = textEl.el.getBoundingClientRect();
    fakerEl.show().css('height', 'auto').css('width', 'auto');
    // if (fakerEl.el.getBoundingClientRect().height < height) {
    //   fakerEl.css('height', `${height}px`);
    // }
    if (fakerEl.el.getBoundingClientRect().width < width) {
      fakerEl.css('width', `${width}px`);
    }
  } else {
    fakerEl.hide();
  }
}

function insertText({ target }, itxt) {
  const { value, selectionEnd } = target;
  const willGotoNextLine = value.slice(selectionEnd);
  const ntxt = `${value.slice(0, selectionEnd)}${itxt}${willGotoNextLine || ' '}`;
  target.value = ntxt;
  target.setSelectionRange(selectionEnd + 1, selectionEnd + 1);

  this.inputText = ntxt;
  this.textlineEl.html(ntxt);
  resetTextareaSize.call(this);
}

function keydownEventHandler(evt) {
  const { keyCode, altKey } = evt;
  if (keyCode !== 13 && keyCode !== 9) {
    evt.stopPropagation();
    setTimeout(() => {
      if ([37, 38, 39, 40].indexOf(keyCode) !== -1) {
        const { selectionStart } = this.textEl.el;
        addCursorToIndex(this.fakerEl, selectionStart - 1);
      }
    });
  }
  if (keyCode === 13 && altKey) {
    if (!this.cell) {
      this.cell = emptyStrCell();
    }
    const textAreaEl = this.textEl.el;
    switch (this.cell[LIST_TYPE]) {
      case LIST_TYPE_ENUM.bullet:
        insertText.call(this, evt, `\n${BULLET_CHAR}`);
        setCaretToPos(textAreaEl, textAreaEl.selectionStart + BULLET_CHAR.length);
        break;
      case LIST_TYPE_ENUM.numeric:
        const oldStart = textAreaEl.selectionStart;
        const oldRows = textAreaEl.value.substring(0, oldStart).split('\n');
        const cursorRow = oldRows[oldRows.length - 1];
        const nextNumericIndex = NUMERIC_REG.test(cursorRow)
          ? (+cursorRow.match(NUMERIC_REG)[0] + 1)
          : 1;
        insertText.call(this, evt, `\n${nextNumericIndex}. `);
        // // add 3 means `. `.length() + 1
        setCaretToPos(textAreaEl, oldStart + (`${nextNumericIndex}`).length + 3);
        break;
      default:
        insertText.call(this, evt, '\n');
    }
    evt.stopPropagation();
    // cursor
    setTimeout(() => {
      const { selectionStart } = this.textEl.el;
      addCursorToIndex(this.fakerEl, selectionStart - 1);
    });
  }
  if (keyCode === 13 && !altKey) evt.preventDefault();
}

function inputEventHandler(evt) {
  const v = evt.target.value;
  // console.log(evt, 'v:', v);
  const { suggest, textlineEl, validator } = this;
  const { cell } = this;
  if (cell !== null) {
    if (('editable' in cell && cell.editable === true) || (cell.editable === undefined)) {
      this.inputText = v;
      if (validator) {
        if (validator.type === 'list') {
          suggest.search(v);
        } else {
          suggest.hide();
        }
      } else {
        const start = v.lastIndexOf('=');
        if (start !== -1) {
          suggest.search(v.substring(start + 1));
        } else {
          suggest.hide();
        }
      }
      textlineEl.html(v);
      resetTextareaSize.call(this);
      this.change('input', v);
    } else {
      evt.target.value = '';
    }
  } else {
    this.inputText = v;
    if (validator) {
      if (validator.type === 'list') {
        suggest.search(v);
      } else {
        suggest.hide();
      }
    } else {
      const start = v.lastIndexOf('=');
      if (start !== -1) {
        suggest.search(v.substring(start + 1));
      } else {
        suggest.hide();
      }
    }
    textlineEl.html(v);
    resetTextareaSize.call(this);
    this.change('input', v);
  }
  const s = this.sheet.data.styles[this.sheet.data.getSelectedCell().style];
  const { selectionStart } = this.textEl.el;
  const inputIndex = selectionStart - 1;
  // calc style index shifter
  let shifter = val => val;
  addCursorToIndex(this.fakerEl, inputIndex);
  switch (evt.inputType) {
    case 'insertText':
      shifter = val => (val === null ? null : val + evt.data.length);
      break;
    case 'deleteContentBackward':
      shifter = val => (val === null ? null : val - 1);
      break;
  }

  if (s && Array.isArray(s.fullStyle)) {
    s.fullStyle = (s.fullStyle || []).reduce((prev, fs) => {
      if (inputIndex >= fs.s && inputIndex < (fs.e !== null ? fs.e : Infinity)) {
        return [
          ...prev,
          {
            ...fs,
            s: fs.s,
            e: shifter(fs.e),
          },
        ];
      }
      if (inputIndex < fs.s) {
        return [
          ...prev,
          {
            ...fs,
            s: shifter(fs.s),
            e: shifter(fs.e),
          },
        ];
      }
      return [...prev, fs];
    }, []);
  }
  instantRender(
    this.fakerEl,
    this.inputText,
    s,
  );
}

function setTextareaRange(position) {
  const { el } = this.textEl;
  setTimeout(() => {
    el.focus();
    el.setSelectionRange(position, position);
  }, 0);
}

function setText(text, position) {
  const { textEl, textlineEl, fakerEl } = this;
  // firefox bug
  textEl.el.blur();

  textEl.val(text);
  textlineEl.html(text);
  const selectedCell = this.sheet.data.getSelectedCell();
  if (selectedCell && selectedCell.style !== undefined) {
    instantRender(fakerEl, text, this.sheet.data.styles[selectedCell.style]);
  } else {
    instantRender(fakerEl, text);
  }
  setTextareaRange.call(this, position);
}

function suggestItemClick(it) {
  const { inputText, validator } = this;
  let position = 0;
  if (validator && validator.type === 'list') {
    this.inputText = it;
    position = this.inputText.length;
  } else {
    const start = inputText.lastIndexOf('=');
    const sit = inputText.substring(0, start + 1);
    let eit = inputText.substring(start + 1);
    if (eit.indexOf(')') !== -1) {
      eit = eit.substring(eit.indexOf(')'));
    } else {
      eit = '';
    }
    this.inputText = `${sit + it.key}(`;
    // console.log('inputText:', this.inputText);
    position = this.inputText.length;
    this.inputText += `)${eit}`;
  }
  setText.call(this, this.inputText, position);
}

function resetSuggestItems() {
  this.suggest.setItems(this.formulas);
}

function dateFormat(d) {
  let month = d.getMonth() + 1;
  let date = d.getDate();
  if (month < 10) month = `0${month}`;
  if (date < 10) date = `0${date}`;
  return `${d.getFullYear()}-${month}-${date}`;
}

export default class Editor {
  constructor(formulas, viewFn, rowHeight, onEdit, onFinishedEdit, sheet) {
    this.sheet = sheet;
    this.onEdit = onEdit;
    this.onFinishedEdit = onFinishedEdit;
    this.viewFn = viewFn;
    this.rowHeight = rowHeight;
    this.formulas = formulas;
    this.suggest = new Suggest(formulas, (it) => {
      suggestItemClick.call(this, it);
    });
    this.datepicker = new Datepicker();
    this.datepicker.change((d) => {
      // console.log('d:', d);
      this.setText(dateFormat(d));
      this.clear();
    });
    this.areaEl = h('div', `${cssPrefix}-editor-area`)
      .children(
        this.textEl = h('textarea', '')
          .on('input', evt => inputEventHandler.call(this, evt))
          .on('paste.stop', () => {})
          .on('keydown', evt => keydownEventHandler.call(this, evt))
          .on('select', (evt) => {
            const { selectionStart, selectionEnd } = evt.target;
            // apply select style
            const selectedTempStyle = {
              s: selectionStart,
              e: selectionEnd,
              bgColor: 'rgb(186, 215, 251)',
            };
            const thisCellStyle = this.sheet.data.styles[this.sheet.data.getSelectedCell().style];
            if (thisCellStyle && Array.isArray(thisCellStyle.fullStyle)) {
              instantRender(
                this.fakerEl,
                this.inputText,
                {
                  ...thisCellStyle,
                  fullStyle: thisCellStyle.fullStyle.concat(selectedTempStyle),
                },
              );
            } else {
              instantRender(
                this.fakerEl,
                this.inputText,
                {
                  ...(thisCellStyle || {}),
                  fullStyle: [selectedTempStyle],
                },
              );
            }
          })
          .on('blur', () => removeAllCursor(this.fakerEl)),
        this.textlineEl = h('div', 'textline'),
        this.suggest.el,
        this.datepicker.el,
        this.fakerEl = h('div', 'miao'),
      )
      .on('mousemove.stop', () => {})
      .on('mousedown.stop', () => {});
    // this.textEl.css('opacity', '0');
    this.selectMode = null;
    this.fakerEl
      .on('mousewheel', evt => evt.stopPropagation())
      .on('mousedown', (evt) => {
        this.startPos = [evt.offsetX, evt.offsetY];
        this.selectMode = 'cursor';
        this.isOnFire = true;
      })
      .on('mousemove', () => {
        this.selectMode = 'multi';
      })
      .on('mouseup', (evt) => {
        if (!this.isOnFire) {
          return;
        }
        this.isOnFire = false;
        const moveTolerance = 5;
        this.endPos = [evt.offsetX, evt.offsetY];
        // eslint-disable-next-line no-undef
        const t = document.getSelection();
        const anchorIndex = +t.anchorNode.parentElement.dataset.selectindex;
        const focusIndex = +t.focusNode.parentElement.dataset.selectindex;
        const selectionStart = Math.min(anchorIndex, focusIndex);
        const selectionEnd = Math.max(anchorIndex, focusIndex) + 1;
        if (this.selectMode === 'multi' && Math.abs(this.endPos[0] - this.startPos[0]) > moveTolerance) {
          this.textEl.el.setSelectionRange(selectionStart, selectionEnd);
        } else {
          // should find hit left or right position of the letter
          const hitTargetIndex = +evt.target.dataset.selectindex;
          // eslint-disable-next-line no-restricted-globals
          if (isNaN(hitTargetIndex)) {
            let nearestIndex;
            let prevDs = null;
            const allTextSpan = Array.from(this.fakerEl.el.querySelectorAll('[data-selectindex]'));
            const allBreakers = [...allTextSpan.filter(i => i.textContent === '\n'), allTextSpan[allTextSpan.length - 1]];
            allBreakers.forEach((item) => {
              // calc the nearest one
              const ds = Math.abs(item.offsetTop - evt.offsetY);
              if (prevDs === null || ds < prevDs) {
                prevDs = ds;
                if (item.textContent === '\n') {
                  nearestIndex = item.dataset.selectindex - 1;
                } else {
                  nearestIndex = item.dataset.selectindex;
                }
              }
            });
            this.textEl.el.setSelectionRange(nearestIndex + 1, nearestIndex + 1);
            addCursorToIndex(this.fakerEl, nearestIndex);
            this.textEl.focus();
            this.selectMode = 'cursor';
            return;
          }
          if (evt.offsetX - evt.target.offsetLeft < evt.target.getBoundingClientRect().width / 2) {
            this.textEl.el.setSelectionRange(hitTargetIndex, hitTargetIndex);
            // add cursor to left side
            addCursorToIndex(this.fakerEl, hitTargetIndex, 'left');
          } else {
            this.textEl.el.setSelectionRange(hitTargetIndex + 1, hitTargetIndex + 1);
            addCursorToIndex(this.fakerEl, hitTargetIndex);
          }
        }
        this.textEl.focus();
        this.selectMode = 'cursor';
      });
    this.el = h('div', `${cssPrefix}-editor`)
      .child(this.areaEl).hide();
    this.suggest.bindInputEvents(this.textEl);

    this.areaOffset = null;
    this.freeze = { w: 0, h: 0 };
    this.cell = null;
    this.inputText = '';
    this.change = () => {};
  }

  setFreezeLengths(width, height) {
    this.freeze.w = width;
    this.freeze.h = height;
  }

  clear() {
    this.onFinishedEdit();
    this.isEditing = false;
    // const { cell } = this;
    // const cellText = (cell && cell.text) || '';
    if (this.inputText !== '') {
      this.change('finished', this.inputText);
    }
    this.cell = null;
    this.areaOffset = null;
    this.inputText = '';
    this.el.hide();
    this.textEl.val('');
    this.textlineEl.html('');
    resetSuggestItems.call(this);
    this.datepicker.hide();
  }

  setOffset(offset, suggestPosition = 'top') {
    const {
      textEl, areaEl, suggest, freeze, el,
    } = this;
    if (offset) {
      this.areaOffset = offset;
      const {
        left, top, width, height, l, t,
      } = offset;
      // console.log('left:', left, ',top:', top, ', freeze:', freeze);
      const elOffset = { left: 0, top: 0 };
      // top left
      if (freeze.w > l && freeze.h > t) {
        //
      } else if (freeze.w < l && freeze.h < t) {
        elOffset.left = freeze.w;
        elOffset.top = freeze.h;
      } else if (freeze.w > l) {
        elOffset.top = freeze.h;
      } else if (freeze.h > t) {
        elOffset.left = freeze.w;
      }
      el.offset(elOffset);
      areaEl.offset({ left: left - elOffset.left - 0.8, top: top - elOffset.top - 0.8 });
      textEl.offset({ width: width - 9 + 0.8, height: height - 3 + 0.8 });
      const sOffset = { left: 0 };
      sOffset[suggestPosition] = height;
      suggest.setOffset(sOffset);
      suggest.hide();
    }
  }

  setCell(cell, validator) {
    this.onEdit();
    this.isEditing = true;
    // console.log('::', validator);
    const { el, datepicker, suggest } = this;
    el.show();
    this.cell = cell;
    const text = (cell && cell.text) || '';
    this.setText(text);

    this.validator = validator;
    if (validator) {
      const { type } = validator;
      if (type === 'date') {
        datepicker.show();
        if (!/^\s*$/.test(text)) {
          datepicker.setValue(text);
        }
      }
      if (type === 'list') {
        suggest.setItems(validator.values());
        suggest.search('');
      }
    }
  }

  setText(text) {
    this.inputText = text;
    // console.log('text>>:', text);
    setText.call(this, text, text.length);
    resetTextareaSize.call(this);
  }
}
