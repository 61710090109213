/* global window */
import _cell from '../core/cell';
import { formulam } from '../core/formula';
import { TEXT_WRAP, TEXT_WRAPPING } from '../constant';
import { getFontSizePxByPt } from '../core/font';
import { findFullStyleByLetterIndex } from '../core/instant_style_render';
import { letterWidthMemo } from '../core/letter_width_cache';

const curDpr = window.devicePixelRatio || 1;

function dpr() {
  return curDpr;
}

function thinLineWidth() {
  return dpr() - 0.5;
}

function npx(px) {
  return parseInt(px * dpr(), 10);
}

function npxLine(px) {
  const n = npx(px);
  return n > 0 ? n - 0.5 : 0.5;
}

function splitArray(arr, splitByFn, initialVal) {
  return arr.reduce((prev, item) => {
    const last = prev.pop();
    const res = [...prev, [...last, item]];
    if (splitByFn(item)) {
      return [...res, initialVal];
    }
    return res;
  }, [initialVal]);
}

function isSameWidth(a, b) {
  return a.size === b.size && a.italic === b.italic && a.bold === b.bold && a.name === b.name;
}

function isAllSameWidth(a, b) {
  return a.size === b.size
    && a.italic === b.italic
    && a.bold === b.bold
    && a.name === b.name
    && a.color === b.color;
}

function layoutBuilderFn(ctx, mtxt, biw, textWrap, fullStyle) {
  const lineWidthConstraint = npx(biw);
  let prevStyle = {};
  const letterList = `${mtxt}`.split('').map((letter, index) => {
    const style = findFullStyleByLetterIndex(fullStyle, index);
    const letterFontSize = getFontSizePxByPt(style.size || 10);

    const ctxStyle = {
      italic: style.italic ? 'italic' : '',
      bold: style.bold ? 'bold' : '',
      size: letterFontSize,
      name: style.name || 'Arial',
    };

    const memoedWidth = letterWidthMemo.getMemoedWidth({
      letter,
      fontName: ctxStyle.name,
      fontSize: letterFontSize,
      isBold: !!style.bold,
    });
    if (memoedWidth) {
      return {
        letter,
        style,
        width: memoedWidth,
      };
    }
    if (Object.keys(prevStyle).length === 0 || !isSameWidth(style, prevStyle)) {
      this.attr({
        font: `${ctxStyle.italic} ${ctxStyle.bold} ${npx(ctxStyle.size)}px ${ctxStyle.name}`,
      });
      prevStyle = ctxStyle;
    }
    const { width } = ctx.measureText(`${letter}`);
    letterWidthMemo.memoWidth(width, {
      letter,
      fontName: ctxStyle.name,
      fontSize: letterFontSize,
      isBold: !!style.bold,
    });
    return {
      letter,
      style,
      width,
    };
  });
  const linesBeforeWrap = splitArray(letterList, item => item.letter === '\n', []);
  const linesDividedByWords = linesBeforeWrap.map(line => splitArray(line, item => item.letter === ' ', []));
  // console.log('letterList: ', letterList);
  // console.log('linesBeforeWrap: ', linesBeforeWrap);
  // console.log('linesDividedByWords: ', linesDividedByWords);
  const res = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const line of linesDividedByWords) {
    let nl = { letters: [], width: 0, maxFontSize: 0 };
    // eslint-disable-next-line no-restricted-syntax
    for (const word of line) {
      const wordWidth = word.reduce((prev, lt) => prev + lt.width, 0);
      const leftEmptyWidth = textWrap ? lineWidthConstraint - nl.width - wordWidth : 1;
      if (leftEmptyWidth > 0) {
        nl.letters.push(...word);
        nl.width += wordWidth;
        nl.maxFontSize = Math.max(nl.maxFontSize, Math.max(...word.map(l => l.style.size || 10)));
      } else {
        // add whole word into second line
        // but when the line is empty, just start from current line
        if (nl.width > 0) {
          res.push(nl);
        }
        nl = { letters: [], width: 0, maxFontSize: 0 };
        if (wordWidth <= lineWidthConstraint) {
          nl.letters.push(...word);
          nl.width += wordWidth;
          nl.maxFontSize = Math.max(nl.maxFontSize, Math.max(...word.map(l => l.style.size || 10)));
        } else {
          // split this word, it can be break
          // eslint-disable-next-line no-loop-func
          word.forEach((lt) => {
            if (nl.width + lt.width < lineWidthConstraint) {
              nl.letters.push(lt);
              nl.width += lt.width;
              nl.maxFontSize = Math.max(nl.maxFontSize, lt.style.size || 10);
            } else {
              res.push(nl);
              nl = { letters: [lt], width: lt.width, maxFontSize: lt.style.size || 10 };
            }
          });
        }
      }
    }
    res.push(nl);
  }
  return res;
}

function calcNtxtsHeight(ntxts) {
  let resHeight = 0;
  for (let i = 0; i < ntxts.length; i += 1) {
    resHeight += getFontSizePxByPt(ntxts[i].maxFontSize) + 2;
    if (resHeight > 409) {
      resHeight = 409;
      break;
    }
  }
  return Math.max(resHeight, 25);
}

export function preCalcRowHeight(draw, viewRange, data) {
  const rowHeightMapping = {};
  viewRange.each((ri, ci) => {
    // if this row is force height do not need to calc the real height
    // just use the forced height value
    if (data.rows.get(ri) && data.rows.get(ri).isForcedHeight) {
      return;
    }

    let nrindex = ri;
    if (data.sortedRowMap.has(ri)) {
      nrindex = data.sortedRowMap.get(ri);
    }
    const cell = data.getCell(nrindex, ci);
    if (cell === null) return;
    const cellText = _cell.render(cell.text || '', formulam, (y, x) => (data.getCellTextOrDefault(x, y)));
    const biw = data.cellRect(ri, ci).width - 12;
    const style = data.getCellStyleOrDefault(nrindex, ci);
    const { fullStyle } = style;
    const ntxts = layoutBuilderFn.call(draw, draw.ctx, cellText, biw, cell[TEXT_WRAPPING] === TEXT_WRAP, fullStyle);
    const cellHeight = calcNtxtsHeight(ntxts);
    if (!rowHeightMapping[ri] || (rowHeightMapping[ri] && cellHeight > rowHeightMapping[ri])) {
      rowHeightMapping[ri] = cellHeight;
    }
  });
  return rowHeightMapping;
}

class DrawBox {
  constructor(x, y, w, h, padding = 0) {
    this.x = x;
    this.y = y;
    this.width = w;
    this.height = h;
    this.padding = padding;
    this.bgcolor = '#ffffff';
    // border: [width, style, color]
    this.borderTop = null;
    this.borderRight = null;
    this.borderBottom = null;
    this.borderLeft = null;
  }

  setCellSpace(cellSpace = 1) {
    this.cellSpace = cellSpace;
  }

  setBorders({
    top, bottom, left, right,
  }) {
    if (top) this.borderTop = top;
    if (right) this.borderRight = right;
    if (bottom) this.borderBottom = bottom;
    if (left) this.borderLeft = left;
  }

  innerWidth() {
    return this.width - (this.padding * 2) - 2;
  }

  innerHeight() {
    return this.height - (this.padding * 2) - 2;
  }

  textx(align, w) {
    const { width, padding } = this;
    let { x } = this;
    if (align === 'left') {
      x += padding;
    } else if (align === 'center') {
      x += width / 2 - w / 2;
    } else if (align === 'right') {
      x += width - padding - w;
    }
    return x;
  }

  texty(align, h) {
    const { height, padding } = this;
    let { y } = this;
    if (align === 'top') {
      y += padding;
    } else if (align === 'middle') {
      y += height / 2 - h / 2;
    } else if (align === 'bottom') {
      y += height - padding - h;
    }
    return y;
  }

  topxys() {
    const { x, y, width } = this;
    return [[x, y], [x + width, y]];
  }

  rightxys() {
    const {
      x, y, width, height,
    } = this;
    return [[x + width, y], [x + width, y + height]];
  }

  bottomxys() {
    const {
      x, y, width, height,
    } = this;
    return [[x, y + height], [x + width, y + height]];
  }

  leftxys() {
    const {
      x, y, height,
    } = this;
    return [[x, y], [x, y + height]];
  }
}

function drawFontLine(type, tx, ty, align, valign, blheight, blwidth) {
  const floffset = { x: 0, y: 0 };
  if (type === 'underline') {
    if (valign === 'bottom') {
      floffset.y = 0;
    } else if (valign === 'top') {
      floffset.y = -(blheight + 2);
    } else {
      floffset.y = -blheight / 2;
    }
  } else if (type === 'strike') {
    if (valign === 'bottom') {
      floffset.y = blheight / 2;
    } else if (valign === 'top') {
      floffset.y = -((blheight / 2) + 2);
    }
  }

  if (align === 'center') {
    floffset.x = blwidth / 2;
  } else if (align === 'right') {
    floffset.x = blwidth;
  }
  this.line(
    [tx - floffset.x, ty - floffset.y],
    [tx - floffset.x + blwidth, ty - floffset.y],
  );
}

class Draw {
  constructor(el, width, height) {
    this.el = el;
    this.ctx = el.getContext('2d');
    this.resize(width, height);
    this.ctx.scale(dpr(), dpr());
  }

  resize(width, height) {
    // console.log('dpr:', dpr);
    this.el.style.width = `${width}px`;
    this.el.style.height = `${height}px`;
    this.el.width = npx(width);
    this.el.height = npx(height);
  }

  clear() {
    const { width, height } = this.el;
    this.ctx.clearRect(0, 0, width, height);
    return this;
  }

  attr(options) {
    Object.assign(this.ctx, options);
    return this;
  }

  save() {
    this.ctx.save();
    this.ctx.beginPath();
    return this;
  }

  restore() {
    this.ctx.restore();
    return this;
  }

  beginPath() {
    this.ctx.beginPath();
    return this;
  }

  translate(x, y) {
    this.ctx.translate(npx(x), npx(y));
    return this;
  }

  scale(x, y) {
    this.ctx.scale(x, y);
    return this;
  }

  clearRect(x, y, w, h) {
    this.ctx.clearRect(x, y, w, h);
    return this;
  }

  fillRect(x, y, w, h) {
    this.ctx.fillRect(npx(x) - 0.5, npx(y) - 0.5, npx(w), npx(h));
    return this;
  }

  fillText(text, x, y) {
    this.ctx.fillText(text, npx(x), npx(y));
    return this;
  }

  /*
    txt: render text
    box: DrawBox
    attr: {
      align: left | center | right
      valign: top | middle | bottom
      color: '#333333',
      strike: false,
      font: {
        name: 'Arial',
        size: 14,
        bold: false,
        italic: false,
      }
    }
    textWrap: text wrapping
  */
  text(mtxt, box, attr = {}, textWrap = true) {
    const { ctx } = this;
    const {
      align, valign, fullStyle,
    } = attr;

    ctx.save();
    ctx.beginPath();
    const biw = box.innerWidth();
    const ntxts = layoutBuilderFn.call(this, ctx, mtxt, biw, textWrap, fullStyle);
    let textHeight;
    switch (valign) {
      case 'middle':
        textHeight = ntxts.reduce((prev, i, index) => {
          return index === 0 ? prev : prev + getFontSizePxByPt(i.maxFontSize) + 2;
        }, 0);
        break;
      default:
        textHeight = ntxts.reduce(
          (prev, i) => prev + getFontSizePxByPt(i.maxFontSize) + 2,
          0,
        ) / dpr();
    }
    const textWidth = Math.max(...ntxts.map(i => i.width)) / dpr();
    let ty = box.texty(valign, textHeight);
    let prevStyle = {};
    ntxts.forEach((line, index) => {
      if (index !== 0 || valign === 'top') {
        ty += (getFontSizePxByPt(line.maxFontSize) + 2) / 2;
      }
      let tx = box.textx(align, textWidth);
      line.letters.forEach((lt, i) => {
        const s = lt.style;
        const letterFontSize = getFontSizePxByPt(s.size || 10);
        if (i === 0 || !isAllSameWidth(s, prevStyle)) {
          this.attr({
            textAlign: 'left',
            textBaseline: 'middle',
            font: `${s.italic ? 'italic' : ''} ${s.bold ? 'bold' : ''} ${npx(letterFontSize)}px ${s.name || 'Arial'}`,
            fillStyle: s.color || '#0a0a0a',
            strokeStyle: s.color || '#0a0a0a',
          });
          prevStyle = s;
        }
        this.fillText(lt.letter, tx, ty);
        if (s.strike) {
          drawFontLine.call(this, 'strike', tx, ty, 'left', 'middle', letterFontSize, lt.width / dpr());
        }
        if (s.underline) {
          drawFontLine.call(this, 'underline', tx, ty, 'left', 'middle', letterFontSize, lt.width / dpr());
        }
        tx += lt.width / dpr();
      });
      ty += (getFontSizePxByPt(line.maxFontSize) + 2) / 2;
    });
    ctx.restore();
    return this;
  }

  border(style, color) {
    const { ctx } = this;
    ctx.lineWidth = thinLineWidth;
    ctx.strokeStyle = color;
    // console.log('style:', style);
    if (style === 'medium') {
      ctx.lineWidth = npx(2) - 0.5;
    } else if (style === 'thick') {
      ctx.lineWidth = npx(3);
    } else if (style === 'dashed') {
      ctx.setLineDash([npx(3), npx(2)]);
    } else if (style === 'dotted') {
      ctx.setLineDash([npx(1), npx(1)]);
    } else if (style === 'double') {
      ctx.setLineDash([npx(2), 0]);
    } else if (style === 'lessMedium') {
      ctx.lineWidth = npx(1);
    }
    return this;
  }

  line(...xys) {
    const { ctx } = this;
    if (xys.length > 1) {
      const [x, y] = xys[0];
      ctx.moveTo(npxLine(x), npxLine(y));
      for (let i = 1; i < xys.length; i += 1) {
        const [x1, y1] = xys[i];
        ctx.lineTo(npxLine(x1), npxLine(y1));
      }
      ctx.stroke();
    }
    return this;
  }

  strokeBorders(box) {
    const { ctx } = this;
    ctx.save();
    ctx.beginPath();
    // border
    const {
      borderTop, borderRight, borderBottom, borderLeft,
    } = box;
    if (borderTop) {
      this.border(...borderTop);
      // console.log('box.topxys:', box.topxys());
      this.line(...box.topxys());
    }
    if (borderRight) {
      this.border(...borderRight);
      this.line(...box.rightxys());
    }
    if (borderBottom) {
      this.border(...borderBottom);
      this.line(...box.bottomxys());
    }
    if (borderLeft) {
      this.border(...borderLeft);
      this.line(...box.leftxys());
    }
    ctx.restore();
  }

  dropdown(box) {
    const { ctx } = this;
    const {
      x, y, width, height,
    } = box;
    const sx = x + width - 15;
    const sy = y + height - 15;
    ctx.save();
    ctx.beginPath();
    ctx.moveTo(npx(sx), npx(sy));
    ctx.lineTo(npx(sx + 8), npx(sy));
    ctx.lineTo(npx(sx + 4), npx(sy + 6));
    ctx.closePath();
    ctx.fillStyle = 'rgba(0, 0, 0, .45)';
    ctx.fill();
    ctx.restore();
  }

  error(box) {
    const { ctx } = this;
    const { x, y, width } = box;
    const sx = x + width - 1;
    ctx.save();
    ctx.beginPath();
    ctx.moveTo(npx(sx - 8), npx(y - 1));
    ctx.lineTo(npx(sx), npx(y - 1));
    ctx.lineTo(npx(sx), npx(y + 8));
    ctx.closePath();
    ctx.fillStyle = 'rgba(255, 0, 0, .65)';
    ctx.fill();
    ctx.restore();
  }

  frozen(box) {
    const { ctx } = this;
    const { x, y, width } = box;
    const sx = x + width - 1;
    ctx.save();
    ctx.beginPath();
    ctx.moveTo(npx(sx - 8), npx(y - 1));
    ctx.lineTo(npx(sx), npx(y - 1));
    ctx.lineTo(npx(sx), npx(y + 8));
    ctx.closePath();
    ctx.fillStyle = 'rgba(0, 255, 0, .85)';
    ctx.fill();
    ctx.restore();
  }

  rect(box, dtextcb, willClipIfOverflow = true) {
    const { ctx } = this;
    const {
      x, y, width, height, bgcolor, cellSpace,
    } = box;
    ctx.save();
    ctx.beginPath();
    ctx.fillStyle = bgcolor || '#fff';
    ctx.rect(
      npxLine(x + cellSpace),
      npxLine(y + cellSpace),
      npx(width - 2 * cellSpace),
      npx(height - 2 * cellSpace),
    );
    if (willClipIfOverflow) {
      ctx.clip();
    }
    ctx.fill();
    dtextcb();
    ctx.restore();
  }
}

export default {};
export {
  Draw,
  DrawBox,
  thinLineWidth,
  npx,
};
