import { isNumericLine } from './bullet_numeric';

function getCellsDecimalCount(cell, calcCellText) {
  // eslint-disable-next-line no-prototype-builtins
  if (cell.hasOwnProperty('tofixed')) {
    return cell.tofixed;
  }
  if (isNumericLine(calcCellText)) {
    return 0;
  }
  const splitByDotList = `${calcCellText}`.split('.');
  if (splitByDotList.length < 2) {
    return 0;
  }
  const deciPart = splitByDotList.pop();
  return Math.min((deciPart || '').length, 10);
}

export {
  // eslint-disable-next-line import/prefer-default-export
  getCellsDecimalCount,
};
