import {
  BULLET_CHAR,
  LIST_TYPE,
  LIST_TYPE_ENUM,
  NUMERIC_REG,
} from '../constant';

function getLineNo(txt, charPos) {
  const str = txt.substring(0, charPos);
  return str.split('\n').length - 1;
}

function getTextAreaSelectLineRange(areaEl) {
  const { selectionStart, selectionEnd } = areaEl;
  const startLineNo = getLineNo(areaEl.value, selectionStart);
  const endLineNo = getLineNo(areaEl.value, selectionEnd);
  return {
    startLineNo,
    endLineNo,
  };
}

function isBulletLine(lineTxt) {
  return lineTxt.startsWith(BULLET_CHAR);
}

function isNumericLine(lineTxt) {
  return NUMERIC_REG.test(lineTxt);
}

function getLineListType(lineTxt) {
  if (isBulletLine(lineTxt)) {
    return LIST_TYPE_ENUM.bullet;
  }
  if (isNumericLine(lineTxt)) {
    return LIST_TYPE_ENUM.numeric;
  }
  return null;
}

function removeNumericLabels(cellText, startLineNo, endLineNo) {
  if (startLineNo == null || endLineNo == null) {
    return cellText.split('\n').map((i) => {
      if (isNumericLine(i)) {
        return i.split(NUMERIC_REG)[1];
      }
      return i;
    }).join('\n');
  }
  return cellText.split('\n').map((i, index) => {
    if (startLineNo <= index && index <= endLineNo && isNumericLine(i)) {
      return i.split(NUMERIC_REG)[1];
    }
    return i;
  }).join('\n');
}

function removeBulletLabels(cellText, startLineNo, endLineNo) {
  if (startLineNo == null || endLineNo == null) {
    return cellText.split('\n').map((i) => {
      if (isBulletLine(i)) {
        return i.substring(BULLET_CHAR.length);
      }
      return i;
    }).join('\n');
  }
  return cellText.split('\n').map((i, index) => {
    if (startLineNo <= index && index <= endLineNo && i.startsWith(BULLET_CHAR)) {
      return i.substring(BULLET_CHAR.length);
    }
    return i;
  }).join('\n');
}

function addBulletLabelForCell(cellText, startLineNo, endLineNo) {
  if (startLineNo == null || endLineNo == null) {
    return cellText.split('\n').map((i) => {
      if (!isBulletLine(i)) {
        return `${BULLET_CHAR}${i}`;
      }
      return `${i}`;
    }).join('\n');
  }

  return cellText.split('\n').map((i, index) => {
    if (startLineNo <= index && index <= endLineNo && !isBulletLine(i)) {
      return `${BULLET_CHAR}${i}`;
    }
    return `${i}`;
  }).join('\n');
}

function addNumericLabelForCell(cellText, startLineNo, endLineNo) {
  return cellText.split('\n').map((i, index) => {
    if (startLineNo == null || endLineNo == null) {
      if (isNumericLine(i)) {
        return `${index + 1}. ${i.split(NUMERIC_REG)[1]}`;
      }
      return `${index + 1}. ${i}`;
    }
    if (startLineNo <= index && index <= endLineNo) {
      if (isNumericLine(i)) {
        return `${index + 1 - startLineNo}. ${i.split(NUMERIC_REG)[1]}`;
      }
      return `${index + 1 - startLineNo}. ${i}`;
    }
    return `${i}`;
  }).join('\n');
}

// read status
function rsRemoveNumericLabel(cell) {
  cell.text = removeNumericLabels(cell.text);
  delete cell[LIST_TYPE];
}

function rsRemoveBulletLabel(cell, data, ri, ci) {
  const newTxt = removeBulletLabels(cell.text);
  data.setCellText(ri, ci, newTxt);
  delete cell[LIST_TYPE];
}

function rsAddNumericLabel(cell, data, ri, ci) {
  const newTxt = addNumericLabelForCell(cell.text);
  data.setCellText(ri, ci, newTxt);
  data.setSelectedCellAttr(LIST_TYPE, LIST_TYPE_ENUM.numeric);
}

function rsAddBulletLabel(cell, data, ri, ci) {
  const newTxt = addBulletLabelForCell(cell.text);
  data.setCellText(ri, ci, newTxt);
  data.setSelectedCellAttr(LIST_TYPE, LIST_TYPE_ENUM.bullet);
}

function rsRemoveBulletAddNumeric(cell, data, ri, ci) {
  rsRemoveBulletLabel(cell, data, ri, ci);
  rsAddNumericLabel(cell, data, ri, ci);
}

function rsRemoveNumericAddBullet(cell, data, ri, ci) {
  rsRemoveNumericLabel(cell);
  rsAddBulletLabel(cell, data, ri, ci);
}

// edit status
function esRemoveNumericLabel(cell, editor) {
  const textAreaEl = editor.textEl.el;
  const { startLineNo, endLineNo } = getTextAreaSelectLineRange(textAreaEl);
  const newTxt = removeNumericLabels(textAreaEl.value, startLineNo, endLineNo);
  textAreaEl.value = newTxt;
  editor.setText(newTxt);
  delete cell[LIST_TYPE];
  // editor.clear();
}

function esRemoveBulletLabel(cell, editor) {
  const textAreaEl = editor.textEl.el;
  const { startLineNo, endLineNo } = getTextAreaSelectLineRange(textAreaEl);
  const newTxt = removeBulletLabels(textAreaEl.value, startLineNo, endLineNo);
  delete cell[LIST_TYPE];
  editor.setText(newTxt);
  // editor.clear();
}

function esAddNumericLabel(cell, editor, data) {
  const textAreaEl = editor.textEl.el;
  const { startLineNo, endLineNo } = getTextAreaSelectLineRange(textAreaEl);
  const newTxt = addNumericLabelForCell(textAreaEl.value, startLineNo, endLineNo);
  textAreaEl.value = newTxt;
  editor.setText(newTxt);
  data.setSelectedCellAttr(LIST_TYPE, LIST_TYPE_ENUM.numeric);
  editor.cell = editor.cell === null
    ? { [LIST_TYPE]: LIST_TYPE_ENUM.numeric }
    : { ...editor.cell, [LIST_TYPE]: LIST_TYPE_ENUM.numeric };
  // editor.clear();
}

function esAddBulletLabel(cell, editor, data) {
  const textAreaEl = editor.textEl.el;
  const { startLineNo, endLineNo } = getTextAreaSelectLineRange(textAreaEl);
  const newTxt = addBulletLabelForCell(textAreaEl.value, startLineNo, endLineNo);
  textAreaEl.value = newTxt;
  editor.setText(newTxt);
  data.setSelectedCellAttr(LIST_TYPE, LIST_TYPE_ENUM.bullet);
  editor.cell = editor.cell === null
    ? { [LIST_TYPE]: LIST_TYPE_ENUM.bullet }
    : { ...editor.cell, [LIST_TYPE]: LIST_TYPE_ENUM.bullet };
  // editor.clear();
}

function esRemoveBulletAddNumeric(cell, editor, data) {
  const textAreaEl = editor.textEl.el;
  const { startLineNo, endLineNo } = getTextAreaSelectLineRange(textAreaEl);
  const newTxt = addNumericLabelForCell(
    removeBulletLabels(textAreaEl.value, startLineNo, endLineNo),
    startLineNo,
    endLineNo,
  );
  delete cell[LIST_TYPE];
  editor.setText(newTxt);
  data.setSelectedCellAttr(LIST_TYPE, LIST_TYPE_ENUM.numeric);
  // editor.clear();
}

function esRemoveNumericAddBullet(cell, editor, data) {
  const textAreaEl = editor.textEl.el;
  const newTxt = addBulletLabelForCell(removeNumericLabels(textAreaEl.value));
  delete cell[LIST_TYPE];
  editor.setText(newTxt);
  data.setSelectedCellAttr(LIST_TYPE, LIST_TYPE_ENUM.bullet);
  // editor.clear();
}

export {
  isBulletLine,
  isNumericLine,
  getTextAreaSelectLineRange,
  getLineListType,
  rsRemoveNumericLabel,
  rsRemoveBulletLabel,
  rsRemoveBulletAddNumeric,
  rsRemoveNumericAddBullet,
  esRemoveNumericLabel,
  rsAddNumericLabel,
  rsAddBulletLabel,
  esAddNumericLabel,
  esRemoveBulletAddNumeric,
  esRemoveBulletLabel,
  esAddBulletLabel,
  esRemoveNumericAddBullet,
};
