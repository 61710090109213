class LetterWidthMemorizer {
  constructor() {
    this.data = {};
  }

  memoWidth(val, {letter, fontName, fontSize, isBold}) {
    if (!this.data[letter]) {
      this.data[letter] = {
        [fontName]: {
          [`${fontSize}`]: {
            [`${isBold}`]: val,
          },
        },
      };
    } else if (!this.data[letter][fontName]) {
      this.data[letter][fontName] = {
        [`${fontSize}`]: {
          [`${isBold}`]: val,
        },
      };
    } else if (!this.data[letter][fontName][`${fontSize}`]) {
      this.data[letter][fontName][`${fontSize}`] = {
        [`${isBold}`]: val,
      };
    } else {
      this.data[letter][fontName][`${fontSize}`][`${isBold}`] = val;
    }
  }

  getMemoedWidth({ letter, fontName, fontSize, isBold }) {
    return this.data[letter]
      && this.data[letter][fontName]
      && this.data[letter][fontName][`${fontSize}`]
      && this.data[letter][fontName][`${fontSize}`][`${isBold}`];
  }
}

// eslint-disable-next-line import/prefer-default-export
export const letterWidthMemo = new LetterWidthMemorizer();
