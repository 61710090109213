class CellSelectionStorage {
  constructor() {
    this.startPosition = null;
  }

  setStart(ri, ci) {
    this.startPosition = { ri, ci };
  }

  get nextEnterShouldGo() {
    return [this.startPosition.ri + 1, this.startPosition.ci];
  }
}

// eslint-disable-next-line import/prefer-default-export
export { CellSelectionStorage };
