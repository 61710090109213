function calcStyleFromFullStyle(fullStyle, letterIndex) {
  return (fullStyle || []).reduce((prev, fs) => {
    if (letterIndex >= fs.s && letterIndex < (fs.e !== null ? fs.e : Infinity)) {
      return { ...prev, ...fs };
    }
    return prev;
  }, {});
}

// eslint-disable-next-line import/prefer-default-export
export { calcStyleFromFullStyle };
