import IconItem from './icon_item';
import { bulletNumericListResolver } from './toolbar_utils';
import {
  esAddBulletLabel,
  esRemoveBulletLabel,
  esRemoveNumericAddBullet,
  rsAddBulletLabel,
  rsRemoveBulletLabel,
  rsRemoveNumericAddBullet,
} from '../../core/bullet_numeric';

export default class BulletList extends IconItem {
  constructor() {
    super('bullet_list');
  }
}

export function bulletListHandler() {
  bulletNumericListResolver.call(this, {
    oldIsNumericFn({
      cell, isEditing, data, ri, ci, editor,
    }) {
      if (!isEditing) {
        rsRemoveNumericAddBullet(cell, data, ri, ci);
      } else {
        esRemoveNumericAddBullet(cell, editor, data);
      }
    },
    oldIsBulletFn({
      cell, data, ri, ci, editor, isEditing,
    }) {
      if (!isEditing) {
        rsRemoveBulletLabel(cell, data, ri, ci);
      } else {
        esRemoveBulletLabel(cell, editor);
      }
    },
    oldIsEmptyFn({
      cell, data, ri, ci, isEditing, editor,
    }) {
      if (!isEditing) {
        rsAddBulletLabel(cell, data, ri, ci);
      } else {
        esAddBulletLabel(cell, editor, data);
      }
    },
  });
}
